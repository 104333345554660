import type { FetchOptions } from "~/utils/branchUtils";

export function useCurrentBranch(opts: Partial<FetchOptions> = { fields: ["*"], status: "published" }) {
  const slug = useBranchSlug();
  const key = `${slug.value}-${stringifyObj(opts, true)}`;
  // console.log("---key:", key);
  const state = useState(key, () => useBranchWithSlug(slug, opts));
  return state.value;
}

export function useBranchWithSlug(slug: string | Ref<string> | ComputedRef<string>, opts?: Partial<FetchOptions>) {
  const _slug = computed(() => unref(slug));
  const asyncData = useAsyncData(() => fetchBranchBySlug(_slug.value, opts), {
    watch: [_slug],
  });
  return asyncData;
}

export function useBranchSlug() {
  const $route = useRoute();
  return computed(() => (typeof $route.params.slug === "string" && $route.params.slug) || "");
}
