export default function stringifyObj(obj: object, sortKeys = false) {
  const pairs: string[] = [];

  const map = new WeakMap();

  const _worker = (obj: object, prefix?: string) => {
    if (obj == null) return;
    if (typeof obj !== "object") return;
    if (map.has(obj)) return;
    map.set(obj, true);
    const keys = Object.keys(obj);
    if (sortKeys && !Array.isArray(obj)) {
      keys.sort((a, b) => a.localeCompare(b));
    }
    keys.forEach((key) => {
      const value = (obj as any)[key];
      if (value == null) return;
      if (prefix) key = `${prefix}[${key}]`;
      if (typeof value === "object") {
        _worker(value, key);
      } else {
        pairs.push(`${key}=${value}`);
      }
    });
  };
  _worker(obj);

  return pairs.join("&");
}
